import { CCol, CContainer, CRow } from '@coreui/react';
import React from 'react';
import {useMutation, useQuery} from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loader from '../../components/Loader/Loader';
import Title from '../../components/Title/Title';
import CustomersService from '../../services/CustomersService';
import AgreementsBox from './components/AgreementsBox';
import ClientBox from './components/ClientBox';
import ScoringBox from './components/ScoringBox';
import { CUSTOMER_TYPE, VERIFICATION_STATUS } from '../../variables';
import VerificationBox from './components/VerificationBox';
import VerificationStatusBadge from '../../components/VerificationStatusBadge/VerificationStatusBadge';
import ScoringResults from "../Application/components/ScoringResults";
import ROLES from "../../roles";
import { useStoreState } from 'easy-peasy';
import Note from "../../components/Note/Note";

const Customer = () => {
  const { id, customerType } = useParams();
  const role = useStoreState((state) => state.auth.role);

  const service = {
    [CUSTOMER_TYPE.INDIVIDUAL_ENTREPRENEUR.SYMBOL]:
      CustomersService.getSingleNatruralPerson,
    [CUSTOMER_TYPE.LEGAL_PERSON.SYMBOL]: CustomersService.getSingleLegalPerson,
    [CUSTOMER_TYPE.NATURAL_PERSON.SYMBOL]:
      CustomersService.getSingleNatruralPerson,
    default: CustomersService.getSingleNatruralPerson,
  }[customerType];

  // -----------------------------------------
  // ---------- GET CUSTOMER NOTE ---------
  // -----------------------------------------
  const {
    data: dataCustomerNote,
    isLoading: isLoadingDataCustomerNote,
    isSuccess: isSuccessDataCustomerNote,
    refetch: refetchDataCustomerNote,
  } = useQuery(`customer-note-${id}`, CustomersService.getNote(id), {
    onError: () => {
      toast.error('Ups... Wystąpił problem z pobraniem notatki do Klienta.');
    },
  });

  // --------------------------------------------
  // ---------- UPDATE CUSTOMER NOTE ---------
  // --------------------------------------------
  const [updateCustomerNote, { isLoading: isLoadingUpdateCustomerNote }] =
      useMutation(CustomersService.updateNote(id), {
        onSuccess: async () => {
          await refetchDataCustomerNote();
          toast.success('Pomyślnie zaktualizowano notatkę.');
        },
        onError: () => {
          toast.error('Ups... Coś poszło nie tak.');
        },
      });

  const handleSubmitCustomerNote = (values) => {
    const newData = {
      text: values?.text ? values.text : '',
      send_email: values.send_email,
    };
    updateCustomerNote(newData);
  };

  const history = useHistory();
  const { data, isLoading, isSuccess, refetch } = useQuery(
    `customer-${id}`,
    service(id),
    {
      onError: (data) => {
        const error =
          data?.response?.data?.data ||
          'Wystąpił błąd podczas pobierania produktu';
        toast.error(error);
        history.goBack();
      },
    }
  );

/*  const isShowScoringBox =
    data?.verification_status_symbol === VERIFICATION_STATUS.ACCEPTED.SYMBOL ||
    data?.verification_status_symbol === VERIFICATION_STATUS.BIK_ERROR.SYMBOL ||
    data?.verification_status_symbol === VERIFICATION_STATUS.PENDING.SYMBOL ||
    data?.verification_status_symbol === VERIFICATION_STATUS.REJECTED.SYMBOL;*/

  const isShowScoringBox = true;

  return (
    <>
      {(isLoading || isLoadingDataCustomerNote || isLoadingUpdateCustomerNote) && <Loader show />}
      {isSuccess && isSuccessDataCustomerNote && (
        <>
          <Breadcrumb
            path={[
              { label: 'Panel administracyjny', url: '/' },
              { label: 'Klienci', url: '/customers', back: true },
              { label: 'Klient' },
            ]}
          />
          <CContainer fluid>
            <main className="c-main">
              <Title>
                {`Klient: ${
                  data?.customer_data?.customer_type_name === 'LEGAL_PERSON'
                    ? data?.customer_data?.company_name
                    : data?.customer_data?.name +
                      ' ' +
                      data?.customer_data?.surname
                }` || ''}
                <VerificationStatusBadge
                  status={data.verification_status_symbol}
                  className="white-space-normal ml-3"
                  style={{
                    fontSize: 12,
                    verticalAlign: 'middle',
                    marginBottom: 3,
                  }}
                />
              </Title>
              <CRow>
                <CCol sm="12" xl="6">

             {[ROLES.VIBE.ADMIN, ROLES.VIBE.MANAGER, ROLES.VIBE.ANALYST].includes(role) && data.verification_status_symbol ===
                VERIFICATION_STATUS.PENDING.SYMBOL && (
                    <VerificationBox
                      customerId={id}
                      customerType={customerType}
                      companyName={data?.customer_data?.company_name}
                      refetch={refetch}
                    />
              )}
                </CCol>
                <CCol sm="12" xl="6">
                  <ScoringResults {...data} />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12" xl="4">
                  <ClientBox
                    {...data.customer_data}
                    enableEdit={true}
                    refetch={refetch}
                  />
                </CCol>
                <CCol sm="12" xl="8">
                  <AgreementsBox agreements={data.agreements} />
                </CCol>
                <CCol sm="12">
                  {isShowScoringBox && (
                    <ScoringBox
                      scoring_summary={data.scoring_summary}
                      income_analysis={data.income_analysis}
                      big_report={data.big_report}
                      customerType={customerType}
                      customerId={id}
                      client_status={data.verification_status_symbol}
                      refetch={refetch}
                    />
                  )}
                </CCol>
              </CRow>

                <div className="mt-5">
                  <Note
                      value={dataCustomerNote}
                      onSubmit={handleSubmitCustomerNote}
                      isSendEmail={true}
                  />
                </div>

            </main>
          </CContainer>
        </>
      )}
    </>
  );
};

export default Customer;
