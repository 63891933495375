import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import CustomersService from '../../../services/CustomersService';
import { CInput } from '@coreui/react';
import { useForm } from 'react-hook-form';
import DataRow from '../../../components/DataRow/DataRow';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import { toast } from 'react-toastify';
import { removeEmptyStringsAndNulls } from '../../../helpers';

const IncomeAnalysisIE = ({ income_analysis, customerId, status, refetch }) => {
  const [updateIncomeCalculatorNP, { isLoading }] = useMutation(
    CustomersService.updateIncomeCalculatorNP(customerId),
    {
      onSuccess: () => {
        toast.success('Pomyślnie zaktualizowano dane.');
        refetch();
      },
      onError: (error) => {
        const isArrError = !!error?.response?.data?.errors;
        const isSingleError = !!error?.response?.data?.data;

        if (isArrError) {
          toast.error('Ups... Coś poszło nie tak...');
          const errors = error.response.data.errors;
          errors.forEach(({ invalid_property, message }) => {
            setError(invalid_property, {
              type: 'manual',
              message: message,
            });
          });
        }
        if (isSingleError) {
          toast.error(
            error?.response?.data?.data || 'Ups... Coś poszło nie tak...'
          );
        }
      },
    }
  );

  const { register, handleSubmit, control, reset, setError, errors } = useForm({
    defaultValues: {
      liabilities_count: '',
      marital_status: '',
      net_income: '',
      number_of_dependents: '',
      property_separation: true,

      balance: '',
      net_month_liabilities_cost: '',
      net_month_living_cost: '',
      net_total_expenses: '',
      total_debt: '',
    },
  });

  useEffect(() => {
    reset({
      ...income_analysis,
      net_income: income_analysis.net_month_income,
      marital_status: income_analysis.marital_status_symbol,
    });
  }, [income_analysis, reset]);

  const onSubmit = (values) => {
    updateIncomeCalculatorNP(removeEmptyStringsAndNulls(values));
  };

  const isEditable = status !== 'ACCEPTED' && status !== 'REJECTED';

  return (
    <>
      {isLoading && <Loader show />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DataRow
          label="Dochód gosp.dom netto / mc"
          value={
            isEditable ? (
              <CInput name="net_income" innerRef={register} />
            ) : (
                income_analysis.net_month_income
            )
          }
          InvalidInput={errors?.net_income?.message}
        />
        <DataRow
          label="Liczba osób w gosp. dom."
          value={
            isEditable ? (
              <CInput name={'number_of_dependents'} innerRef={register} />
            ) : (
              income_analysis.number_of_dependents
            )
          }
          InvalidInput={errors?.number_of_dependents?.message}
        />
        <DataRow
          label="Koszty utrzymania / mc"
          value={income_analysis?.net_month_living_cost}
        />
        <DataRow
          label="Ilość zobowiązań"
          value={income_analysis?.liabilities_count}
        />
        <DataRow
          label="Łączne zadłużenie"
          value={income_analysis?.total_debt}
        />
        <DataRow
          label="Obsługa zadłużenia / mc"
          value={income_analysis?.net_month_liabilities_cost}
        />
        <DataRow
          label="Łączne wydatki / mc"
          value={income_analysis?.net_total_expenses}
        />
        <DataRow
          containerClass="bg-light-grey"
          label="Nadwyżka / niedobór / mc"
          value={income_analysis?.balance}
        />
        <DataRow
          label="Stan cywilny / rozdzielność majątkowa"
          value={
            !income_analysis.marital_status_text
              ? '-'
              : income_analysis?.marital_status_text +
                ' / ' +
                (income_analysis?.property_separation ? 'TAK' : 'NIE')
          }
        />
        {isEditable && (
          <Button className="w-100 mt-2" type="submit">
            Zapisz
          </Button>
        )}
      </form>
    </>
  );
};

export default IncomeAnalysisIE;
