import { CTextarea } from '@coreui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../Button/Button';
import DataCard from '../DataCard/DataCard';

const Note = ({
  isSendEmail = false,
  onSubmit,
  title,
  value,
  name = 'text',
}) => {
  const { register, getValues, reset } = useForm({
    defaultValues: {
      [name]: value,
    },
  });

  useEffect(() => {
    reset({ [name]: value });
  }, [name, reset, value]);

  const handleUpdateNote = (e, obj) => {
    e.preventDefault();
    const values = getValues();
    onSubmit({ ...values, ...obj });
  };

  return (
    <form>
      <DataCard title={title ? title : 'Notatka'}>
        <CTextarea
          spellCheck={false}
          rows={10}
          style={{ marginTop: '1.25rem' }}
          name={name}
          innerRef={register}
        />
        <div className="d-flex justify-content-end gap-2">
          <Button
            onClick={(e) => handleUpdateNote(e, { send_email: false })}
            className="mt-4"
            type="submit"
          >
            Zapisz notatkę
          </Button>
          {isSendEmail && (
            <Button
              onClick={(e) => handleUpdateNote(e, { send_email: true })}
              className="mt-4"
              type="button"
            >
              Zapisz i wyślij notatkę
            </Button>
          )}
        </div>
      </DataCard>
    </form>
  );
};

export default Note;
